
import React from 'react';

const Project = () => {
  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
    
    </div>
  );
};

export default Project;
